@use 'sass:math';

$alert-color: #fff;
$oko-color-1: #88b440;
$oko-color-2: #4b5b5f;
$oko-color-3: #313434;
$oko-bgcolor: #cdd6c7;
$oko-white: #fff;
$facebook-blue: #4267b2;

$mobile: '(max-width: 767px)';
$tablet: '(min-width: 768px) and (max-width: 1023px)';
$desktop: '(min-width: 1024px)';

@import url('https://fonts.googleapis.com/css?family=Raleway:400,900');



// ------------------------
// general settings
// ------------------------

.errorlist {
  color: $alert-color;
}

body {
  background-color: $oko-bgcolor;
  color: $oko-color-3;
  font-family: 'Raleway', sans-serif;
}

a,
a:visited {
  color: $oko-color-1;
}

a:hover {
  color: $oko-color-2;
}

.btn.btn-primary {
  background-color: $oko-color-1;
}

.btn.btn-primary:hover {
  background-color: $oko-color-2 !important;
}

.container, .container-sm {
  @media #{$mobile} { max-width: none; }
}
// end general settings



// ------------------------
//  fonts & colors
// ------------------------
h1, h2, h3, h4, h5, h6 {
  font-family: 'Raleway', sans-serif;
}

h1.fh5co-lead  {
  font-size: 40px !important;
  text-transform: none !important;
  @media #{$mobile} { line-height: 34px !important; }
  @media #{$tablet} { line-height: 40px !important; }
  @media #{$desktop} { line-height: 44px !important; }
}

h2.fh5co-sub-lead {
  font-size: 24px !important;
  text-transform: none !important;
  line-height: 34px !important;
  @media #{$mobile} { line-height: 30px !important; }
  @media #{$tablet} { line-height: 33px !important; }
  @media #{$desktop} { line-height: 40px !important; }
}

.section-heading h2:after,
#fh5co-header #navbar li a span:before {
  background: $oko-color-1;
}

#fh5co-header.navbar-fixed-top #navbar li.active a,
#fh5co-header.navbar-fixed-top #navbar li.active {
  color: $oko-color-1;
}

#fh5co-header.navbar-fixed-top #navbar li a:hover { color: $oko-color-2; }
// end fonts & colors



// ------------------------
//  services
// ------------------------

h3 {
  color: $oko-color-1;
}

.indicator-number {
  background-color: $oko-color-2;
  border-radius: 1em;
  display: block;
  font-size: 1.5em;
  height: 2em;
  text-align: center;
  width: 2em;
}

img.thumb {
  border: 2px solid #dff0d8;
  box-shadow: 0px 5px 15px #888;
}

.box { border: 2px solid #dff0d8; }

.gallery-container {
  margin: -5em 0 2em;
}

span.img-zoom {
  width: 164px;
  height: 164px;
  background: center center no-repeat rgba(0, 0, 0, 0.4);
  position: absolute;
  text-align: center;
  line-height: 164px;
  z-index: 10;
  top: 10px;

  &:before {
    content: "\f002";
    font-family: "FontAwesome";
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
    color: white;
    font-size: 1.75em;
    position: relative;
    width: 164px;
    height: 164px;
  }
}

.lightSlider {
  height: 200px !important;
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;

  a {
    top: 10px !important;

    img {
      border: 2px solid rgb(223, 240, 216);
      border-radius: 2px;
      box-shadow: rgb(136, 136, 136) 0px 1px 10px 1px;
      margin-top: 10px;
    }
  }
}

.lSSlideOuter {
  .lsPager, .lSPager {
    &.lSpg {
      li {
        a {
          background-color: #bdeead;
          width: 15px;
          height: 15px;
        }
        &.active a, a:hover {
          background-color: #57b237 !important;
        }
      }
    }
  }
}
// end services



// ------------------------
//  navbar
// ------------------------

.navbar {
  padding: 0;
}

.navbar-expand-md {
  .navbar-nav {
    .nav-link {
      padding: 25px 15px;
    }

    li a {
      line-height: 20px;
    }
  }
}

.navbar-brand {
  padding: 0;
  img { margin: 3px 0; }
}

.fh5co-nav-toggle i,
.fh5co-nav-toggle i::before,
.fh5co-nav-toggle i::after,
.fh5co-nav-toggle.active i::before,
.fh5co-nav-toggle.active i::after {
  background-color: $oko-color-1;
}

[data-toogle="collapse"] {
  background-color: $oko-color-1;
}

@media #{$mobile} {
  #fh5co-header #navbar li.active a { color: $oko-color-1 !important; }
  .fh5co-tns-text-wrap { padding: 2em !important; }
  h1.fh5co-lead { font-size: 1.8em !important; }
  h2.fh5co-sub-lead { font-size: 1.5em !important; }
}

.navbar-fixed-top .navbar-collapse, .navbar-fixed-bottom .navbar-collapse {
  max-height: 100%;
}
// end navbar



// ------------------------
//  language chooser flags
// ------------------------

.flag {
  height: auto;
  width: 20px;
  margin: 0 4px 2px 0;
}

.lang-dropdown-toggle {
  background-color: transparent;
  border: 0;
  font-size: 0.8em;
  outline: none;
  padding: 22px 15px;
  color: inherit;

  + .dropdown-menu {
    margin-top: -20px;
    @media #{$mobile} { border: 0; }
  }

  &:focus {
    outline: none;
  }
}

.lang {
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: baseline;
}

.lang-item a {
  color: gray !important;
  font-size: 0.9em;
  padding: 6px 12px !important;
}
// end language chooser flags



// ------------------------
//  carousel
// ------------------------

.my-slider {
  height: 100vh;

  .slider-item {
    height: 100vh;
  }
}

// end carousel



// ------------------------
// contact form
// ------------------------

label.required {
  font-weight: normal;
}

.djangocms-maps {
  border: 2px solid #ccc;
}

label[for=id_recaptchaplugin_1] {
  display: none;
}
// end contact form



// ---------------------------------------------------------------------------------------------
//  manual configuration for the spacer plugin in: https://github.com/aldryn/aldryn-bootstrap3/
//  requires bootstrap 3
// ---------------------------------------------------------------------------------------------
$line-height-computed: 1em;

.spacer {
  margin-bottom: $line-height-computed;
  margin-top: $line-height-computed;
}

.spacer-xs {
  margin-bottom: math.div($line-height-computed, 2);
  margin-top: math.div($line-height-computed, 2);
}

.spacer-sm {
  margin-bottom: math.div($line-height-computed, 1.5);
  margin-top: math.div($line-height-computed, 1.5);
}

.spacer-md {
  @extend .spacer;
}

.spacer-lg {
  margin-bottom: $line-height-computed * 2;
  margin-top: $line-height-computed * 2;
}

.spacer-zero {
  margin: 0;
}

.spacer:after,
.spacer-xs:after,
.spacer-sm:after,
.spacer-md:after,
.spacer-lg:after,
.spacer-zero:after {
  clear: both;
  content: '';
  display: table;
}

.oko-icon {
  background-color: $oko-color-1;
  border-radius: 50%;
  color: $oko-white;
  height: 30px;
  padding-top: 7px;
  text-align: center;
  width: 30px;
}

a.facebook-link {
  border: 0 !important;

  & i:hover {
    background-color: #4b5b5f;
    border: 2px solid white;
    text-decoration: none;
  }

  img {
    width: 35px;
    height: auto;
    margin-left: 20px;
    border: 2px solid white;

    &:hover {
      border: 2px solid $oko-color-1;
    }
  }


  .oko-icon-facebook {
    background: $facebook-blue;
    border: 2px solid $facebook-blue;
    border-radius: 5px;
    padding: 11px 12px 16px 12px;
    color: white;
  }

  .oko-icon-facebook-small {
    background: #4267b2;
    border: 1px solid #4267b2;
    padding: 4px;
    color: white;
  }

}

// end spacer

// ------------------------
// cookieConsent
// ------------------------

#s-all-bn, #c-p-bn {
  background-color: #88b440 !important;
}

// end cookieconsent
